import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const PrimObjectives = () => {
  return (
    <section className="case-study cs-objective">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Objectives</h2>
                    <div className="sub-content mt-5">
                        <ul>
                            <li>Make a video that creates awareness about Prim Technologies and what it offers.</li>
                            <li>The content should be sophisticated and should focus on how Prim unleashes the power of mentoring with cutting-edge technology.</li>
                            <li>The video should urge the audience to start connecting, integrating, and networking with Prim Technologies</li>
                        </ul>                        
                    </div>
                    <StaticImage src="../../../images/case-studies/prim-technologies/objectives.png" alt="Prim Technologies case study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={451}/>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default PrimObjectives
