import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const PrimScript = () => {
  return (
    <section className="case-study cs-script pb-0">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Script</h2>
                    <div className="sub-content mt-5">
                        <p>Now that we had a concept, the next challenge was to transform it into a script with appealing visuals and voiceover. The first few seconds of the video are crucial as it makes the audience decide whether they want to go ahead watching the video. The video had to begin with a catchy visual and voiceover that would make the audience feel curious and stay hooked on the video. Every frame was meticulously planned, with one scene seamlessly flowing to the next and a compelling voiceover that enhanced the beauty of each frame.</p>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid>
            <Row>
                <Col xs={12} className="px-0 mt-5">
                    <StaticImage src="../../../images/case-studies/prim-technologies/concept.png" alt="Prim Technologies case study | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={1920} height={1005}/>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default PrimScript
