import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const PrimConcept = () => {
  return (
    <section className="case-study concept">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Concept</h2>
                    <div className="sub-content mt-5">
                        <p>Once we had a clear understanding of the brief, we brainstormed to create a concept that would help us do justice to the requirement and convey the message in an interesting way. A concept is the very crux of every video. It determines the direction and flow of the video, the storyline and style to be followed, the duration, and the entire mood of the video. The client had their own preferences and ideas, and it helped us a great deal during conceptualization. For instance, they preferred the video to follow the 2D Isometric Style. Incorporating their inputs, we weaved a story about creating an ideal virtual community where like-minded people get to interact and share ideas.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default PrimConcept
