import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const PrimBrief = () => {
  return (
    <section className="case-study cs-objective">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">The Brief and Kick-off Call</h2>
                    <div className="sub-content mt-5">
                        <p>To kick off the project, a clear understanding of the project was needed in terms of what Prim is, and the messages to be conveyed through the video. We sent the client a questionnaire with relevant questions that would help us understand the project better. The client filled up the questionnaire with specific answers. We also had calls with the client where additional information about the platform and their preferences were discussed. The questionnaire and the briefing calls were crucial in laying a foundation for us to kick off the project without any confusion.</p>
                    </div>
                    <StaticImage src="../../../images/case-studies/prim-technologies/brief.png" alt="Prim Technologies case study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={451}/>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default PrimBrief
