import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const PrimOverview = () => {
  return (
    <section className="case-study cs-overview pb-0">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <div className="sub-content">
                        <p>To create a video that would give an overview of their global virtual mentoring system, the Prim team approached us. They had come across some of the videos we had done in the blockchain segment and decided we would be the best team to pull this off. They wanted to showcase how the platform empowers people to grow by connecting with like-minded and driven people through blockchain technology. Equipped with the experience of breaking down complex technical topics into comprehensive and appealing videos, we were more than confident to take up the project and deliver it in the best possible way.</p>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid>
            <Row>
                <Col xs={12} className="px-0 mt-5">
                    <StaticImage src="../../../images/case-studies/prim-technologies/intro.png" alt="Prim Technologies case study | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={1920} height={1004}/>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default PrimOverview
