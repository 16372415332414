import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const PrimProcess = () => {
  return (
    <section className="case-study cs-process pb-0">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Our process</h2>
                    <div className="sub-content mt-5">
                        <ul>
                            <li>The Brief and Kick-off Call</li>
                            <li>Concept</li>
                            <li>Script</li>
                            <li>Storyboard</li>
                            <li>Styleframes</li>
                            <li>Animation</li>
                            <li>Voice Over</li>
                            <li>SFX design</li>
                        </ul>                        
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid>
            <Row>
                <Col xs={12} className="px-0 mt-5">
                    <StaticImage src="../../../images/case-studies/prim-technologies/explainer-video-process-wowmakers.png" alt="Prim Technologies case study | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={1920} height={1005}/>
                </Col>
            </Row>
        </Container>        
    </section>
        
  )
}

export default PrimProcess
