import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const PrimVoiceover = () => {
  return (
    <section className="case-study cs-voice-over">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Voiceover</h2>
                    <div className="sub-content mt-5">
                        <p>The voiceover plays an important role in how the video will turn out to be. From our large database of voiceover artists that belong to different nationalities and gender, we picked a few options that were closely aligned with the client’s preferences. It was essential that we picked a voiceover artist who was capable of delivering a voiceover with the right accent and voice modulation that would work best for the client’s market. For this, we sent a few samples to the client who further chose one that met their criteria. The written voiceover was recorded by the chosen artist who added the apt intonation, modulation, pace, accent, and emotions, and it gave the video a whole different feel.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default PrimVoiceover
