import React from "react"
import { Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"

const PrimMain = () => {
  return (
    <div className="main">
        <Container>
            <Row>
                <Col xs={12} className="branding-main">
                    <p className="text-center top-link-back"> <span><Link to="/case-studies" >Casestudy</Link></span>
                        / <span>PRIM Technologies</span> </p>
                    <h1 className="b-header">
                        How we created a compelling video for a virtual mentoring platform - A case study.
                    </h1>
                    <Col lg={9} md={10} className="b-desc">
                        <Row>
                            <Col md={4} className="desc text-center">
                                <p className="title">Client</p>
                                <p className="sub-title"> PRIM Technologies</p>
                            </Col>
                            <Col md={4} className="desc text-center">
                                <p className="title"> Industry </p>
                                <p className="sub-title">Technology</p>
                            </Col>
                            <Col md={4} className="desc text-center">
                                <p className="title"> Geography </p>
                                <p className="sub-title">USA</p>
                            </Col>                            
                        </Row>
                    </Col>
                </Col>
            </Row>
        </Container>            
    </div>    
  )
}

export default PrimMain
