import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const PrimStyleFrame = () => {
  return (
    <section className="case-study cs-style-frame pb-0">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Style frame</h2>
                    <div className="sub-content mt-5">
                        <p>Once the storyboard was approved, our Illustrators started out with the illustration for the final video by developing the look and feel of the visuals. The Illustrators made sure to use the brand colors and visually narrate the story through detailed illustrations. The challenge lay in visually creating a virtual community in Isometric style that would be instrumental in making the audience have an understanding of how the platform works.</p>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid>
            <Row>
                <Col xs={12} className="px-0 mt-5">
                    <StaticImage src="../../../images/case-studies/prim-technologies/style-frame-1.png" alt="Prim Technologies case study | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={1920} height={1004}/>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default PrimStyleFrame
