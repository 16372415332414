import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const PrimInfo = () => {
  return (
    <section className="case-study cs-info">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <Row>
                        <Col xs={12} className="mb-5 text-center">
                            <div className="gatsby-resp-iframe-wrapper">
                                <iframe
                                    width="895"
                                    height="504"
                                    src="https://www.youtube.com/embed/TYB7YTQXwXY?controls=0"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                ></iframe>
                            </div>
                        </Col>
                        <Col md={6}>
                            <h3 className="text-center analytics_text">2D Explainer <br/>
                                <span>Video</span>
                            </h3>                        
                        </Col>
                        <Col md={6}>
                        <h3 className="text-center analytics_text">84<br/>
                                <span>Seconds</span>
                            </h3>                        
                        </Col>                    
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default PrimInfo
