import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const PrimSfxDesign = () => {
  return (
    <section className="case-study cs-sfs-design">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">SFX design</h2>
                    <div className="sub-content mt-5">
                        <p>Now that we had a great video with an appealing voiceover, we knew it had to be pepped up with a piece of captivating background music and sound effects for better impact. For this, a catchy background music piece and sound effects were mixed and it contributed greatly to making the video all the more appealing!</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default PrimSfxDesign
