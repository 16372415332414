import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"

import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import PrimMain from "../../components/case-studies/prim-technologies/primMain"
import PrimInfo from "../../components/case-studies/prim-technologies/primInfo"
import PrimTestimonials from "../../components/case-studies/prim-technologies/primTestimonials"
import PrimOverview from "../../components/case-studies/prim-technologies/primOverview"
import PrimObjectives from "../../components/case-studies/prim-technologies/primObjectives"
import PrimProcess from "../../components/case-studies/prim-technologies/primProcess"
import PrimBrief from "../../components/case-studies/prim-technologies/primBrief"
import PrimConcept from "../../components/case-studies/prim-technologies/primeConcept"
import PrimScript from "../../components/case-studies/prim-technologies/primScript"
import PrimStoryBoard from "../../components/case-studies/prim-technologies/primStoryBoard"
import PrimStyleFrame from "../../components/case-studies/prim-technologies/primStyleFrame"
import PrimAnimation from "../../components/case-studies/prim-technologies/primeAnimation"
import PrimVoiceover from "../../components/case-studies/prim-technologies/primVoiceover"
import PrimSfxDesign from "../../components/case-studies/prim-technologies/primSfxDesign"
import PrimResult from "../../components/case-studies/prim-technologies/primResults"
import PrimMoreCs from "../../components/case-studies/prim-technologies/primMoreCs"
import DirectContact from "../../components/global/directContact"

import csPrimFeaturedImage from "../../../static/case-studies/prim-technologies/prim-tech-fetured-img.png"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/case-studies/prim-technologies.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={csPrimFeaturedImage}
      title="PRIM Case Study | 2D Animation Explainer Video | Technology"
      description="A first-hand insight into how our processes have been implemented in the explainer video we've worked on for PRIM Technologies."
    />
  </> 
)

const PrimTechnologies = () => {
    return (
      <Layout>
        <Seo
          bid="cs-prim-tech"
          bclass="case-studies-page"
        ></Seo>
        <ScrollIndicationHeightZero />
        <PrimMain />
        <PrimInfo />
        <PrimTestimonials />
        <PrimOverview />
        <PrimObjectives />
        <PrimProcess />
        <PrimBrief />
        <PrimConcept />
        <PrimScript />
        <PrimStoryBoard />
        <PrimStyleFrame />
        <PrimAnimation />
        <PrimVoiceover />
        <PrimSfxDesign />
        <PrimResult />
        <PrimMoreCs />
        <DirectContact />
      </Layout>
    )
}
export default PrimTechnologies
