import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const PrimTestimonials = () => {
  return (  
    <section className="case-study testimonial">
        <Container>
            <Row className="justify-content-center">
                <Col lg={8}>
                    <div className="t-block text-center">
                        <blockquote className="mb-5">
                            <p>"We have worked with countless consulting and professional services firms/vendors -- Wowmakers is one of the best!  It was so enjoyable to work with the Wowmakers team due to their quality, professionalism, and ability to make recommendations on marketing.  They knew what we wanted and delivered it with our high satisfaction."</p>
                        </blockquote>
                        <StaticImage src="../../../images/case-studies/prim-technologies/binh-hua.png" alt="Prim Technologies case study Testimonials" placeholder="blurred" className="img-fluid profile-circle cs-image-min-width" width={100} height={100}/>
                        <p className="name text-center mt-3"><strong>Binh Hua</strong></p>
                        <small className="text-center">CEO, Prim Technologies</small>                        
                    </div>
                </Col>
            </Row>
        </Container>
    </section>     
  )
}

export default PrimTestimonials
