import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const PrimResult = () => {
  return (
    <section className="case-study cs-result">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Result</h2>
                    <div className="sub-content mt-5">
                        <p>The Prim team uploaded the video on their Youtube channel, their website, and other social media platforms. The Prim team was mighty impressed with our work and was quite generous with their feedback.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default PrimResult
