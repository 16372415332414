import React from "react"
import PrimAnimationVideo from "../../../images/case-studies/prim-technologies/prim-technologies-animation-wowmakers.mp4"
import { Col, Container, Row } from "react-bootstrap"

const PrimAnimation = () => {
  return (
    <section className="case-study cs-animation">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Animation</h2>
                    <div className="sub-content mt-5 mb-4">
                        <p>The style frames were approved, and we embarked on the next phase - the animation. The animators did a fantastic job breathing life into the frames by animating them. What started as a mere thought was now materializing into a comprehensive, visually appealing video.</p>
                    </div>
                    <video width="839" height="473" className="vid" autoPlay={true} loop muted autoplay playsinline>
                        <source src={PrimAnimationVideo} type="video/mp4" />
                    </video>                    
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default PrimAnimation
