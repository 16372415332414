import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const PrimStoryBoard = () => {
  return (
    <section className="case-study cs-storyboard">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Storyboard</h2>
                    <div className="sub-content mt-5">
                        <p>Once the script was approved, preparations were done to convert the script into a storyboard. Our Illustrators spent some time reading the script and understanding the story frame by frame. The storyboard was done in a pretty comprehensive way with rough sketches along with descriptions and voiceover. This would give the client a basic idea how the final video would look like.</p>
                        <p><a href="/case-studies/prim-technologies/prim-technologies-explainer-video-storyboard.pdf" target="_blank" >You can check out the final story board here</a></p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default PrimStoryBoard
